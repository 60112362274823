import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Scrolable } from './scrolable';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() service: Scrolable;
  @Input() template: TemplateRef<any>


  constructor(private notification: NotificationService) {}

  ngOnInit(): void {
    this.service.get(this.service.page, this.service.size).subscribe(next => {
      this.service.items = next.data;
      this.service.total = next.length
      })
  }
}
