import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../../service/rest/product.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {CategoryService} from "../../../service/rest/category.service";
import {NotificationService} from "../../../core/services/notification.service";
import {remove} from "lodash";
import { AttributeService } from 'src/app/service/rest/attribute.service';
import { Attribute, CartSlot, FilterBuilder, Product, replaceRankedList } from 'gul-si-commons';
import { BasicFormComponent } from 'src/app/core/component/form/basic-form/basic-form.component';
import { MatDialogRef } from '@angular/material/dialog';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { NodeService } from 'src/app/service/rest/tree.service';

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent extends BasicFormComponent<Product> implements OnInit {

  faUpload = faUpload

  public productId: string;
  categories: any[]
  attributes: any[]
  productSearch = new FormControl('')
  searchResult = []

  constructor(private catalogue: ProductService,
              private activatedRoute: ActivatedRoute,
              private notification: NotificationService,
               public nodeService: NodeService,
              private route: Router,
              protected fb: FormBuilder) {
    super({object: null}, fb, null);
  }



  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
        this.productId = params['productId'];
      },
      error => {
        this.notification.error('ERREUR', error)
      });
    this.catalogue.getById(this.productId).subscribe((o) => {
         let product = o.data.pop()

         // fetch attributes from the tree then merge it in the product.
         // if value exist in current product, it keeps the old value in the new merged list
          this.nodeService.path(product.associatedNode).subscribe( (data) => {
            let attributes = []
            data.data.forEach(node => {
              if (node.value)
                attributes = attributes.concat(node.value)
            });
            attributes.map((a) => {
              let found = product.attributes.find((e) => {
                return e.label === a.label
              })
              if(found)
                a.value = found.value
            })
            product.attributes = attributes
            this.givenObject = product
            super.ngOnInit()
            this.objectAsForm.controls.articles.value = this.objectAsForm.controls.articles.value.map( p => {p.product = new Product().fromObject(p.product); return p})
          })
          }
      )
      this.productSearch.valueChanges.subscribe((change) => {
        if(change.length >= 3) {
          let filter = new FilterBuilder().fieldEquals('name', change, true)
          this.catalogue.getFiltered(filter, 0, 10).subscribe(next => {
            this.searchResult = next.data;
            },
            error => {
              this.notification.error('ERROR filters', error)
            }
        )
        }      })
  }

  submit(): any {
    this.validateForm()
    this.object.articles = this.objectAsForm.controls.articles.value
    this.object.offers = this.objectAsForm.controls.offers.value
    this.object.articles = this.object.articles.map(p => { delete p['product']; return p })
      this.catalogue.update(this.productId,
      this.object
    ).subscribe((res) => {
        this.notification.succes('OK', "Produit sauvegardé")
        this.route.navigate(['/catalogue', 'arborescence'])
      },
      error => {
        this.notification.error('ERREUR', error)
      })
  }



  delete(): void {
    this.catalogue.delete(this.productId).subscribe(res => {
        this.notification.succes('OK', "Produit supprimé")
        this.route.navigate(['/catalogue'])
      },
      error => {
        this.notification.error('ERREUR', error)
      })
  }


  onFileSelected(event, index) {
    const file:File = event.target.files[0];
    if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const upload$ = this.catalogue.uploadImage(this.productId, index, formData);
        upload$.subscribe((e) => {
          const img = document.getElementById("image"+ index);
          this.objectAsForm?.controls?.images?.controls[index]?.controls?.url?.setValue(e.data[0].images[index].url)
          setTimeout(() => {
            img['src'] = e.data[0].images[index].url  + "?t=" + new Date().getTime()
          }, 1000);
          this.notification.succes('OK', `Upload terminé sauvegarde pour voir les changements`)
        }, error => {
          this.notification.error('ERROR', error)
        });
    }
}

  addToBundle(item: CartSlot) {
    const index = this.objectAsForm.controls.articles.value.findIndex(p => p?.product?._id === item.product._id)
    if(index !== -1){
      this.objectAsForm.controls.articles.value[index].qte = this.objectAsForm.controls.articles.value[index].qte + 1
    } else {
      this.objectAsForm.controls.articles.value.push({product: item.product, productId: item.product._id, qte: 1})
    }
  }

  removeFromBundle(item: CartSlot) {
    const index = this.objectAsForm.controls.articles.value.findIndex(p => p.product._id === item.product._id)
    if(this.objectAsForm.controls.articles.value[index].qte === 1){
      this.objectAsForm.controls.articles.value.splice(index, 1)
    } else {
      this.objectAsForm.controls.articles.value[index].qte = this.objectAsForm.controls.articles.value[index].qte - 1
    }
  }

  changeOfferVisibility(_id, isVisible) {
    const index = this.objectAsForm.controls.offers.value.findIndex(p => p._id === _id)
    this.objectAsForm.controls.offers.controls[index].controls.isVisible.setValue(isVisible)
  }

  getArticles() {
    let res = this.objectAsForm
    return res
  }
}
