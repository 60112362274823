import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InputComponent } from './input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { EmailDirective } from './validation/email.directive';
import { DefaultInputComponent } from './default-input/default-input.component';



@NgModule({
  declarations: [
    InputComponent,
    DefaultInputComponent,
  ],
  exports: [
    EmailDirective,
    InputComponent,
    DefaultInputComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    EmailDirective,
  ]
})
export class InputModule { }
