import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {SimpleRestService} from "../../core/services/simple-rest.service";

@Injectable({
  providedIn: 'root'
})
export class UserInfoService extends SimpleRestService {

  constructor(public http: HttpClient) {
    super(http, 'users', ['referential'])
  }
}
