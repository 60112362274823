import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {MatSidenavModule} from '@angular/material/sidenav';
import { environment } from '../environments/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthInterceptor} from './core/services/auth/auth.interceptor';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatListModule} from '@angular/material/list';
import {MatButtonModule} from "@angular/material/button";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import { HomeComponent } from './page/home/home.component';
import { ProductPageComponent } from './page/navigation/product/product-page.component';
import { CatalogueComponent } from './page/catalogue/catalogue.component';
import { ProductEditComponent } from './page/catalogue/product-edit/product-edit.component';
import {MatBadgeModule} from "@angular/material/badge";
import { CartPageComponent } from './page/cart/cart.component';
import { MatDialogModule} from "@angular/material/dialog";
import { ProductInventoryComponent } from './page/catalogue/product-inventory/product-inventory.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {MatTooltipModule} from '@angular/material/tooltip';
import { OrderDashboardComponent } from './page/oms/order-dashboard/order-dashboard.component';
import { UserDashboardComponent } from './page/user-dashboard/user-dashboard.component';
import { SearchComponent } from './page/navigation/search/search.component';
import { UniversComponent } from './page/navigation/univers/univers.component';
import { PromotionComponent } from './page/catalogue/promotion/promotion.component';
import { ComponentModule } from './core/component/component.module';
import { OrderModule } from './page/oms/order/order.module';


// // dont load angular when in ssr
// declare var require: any;
// const Keycloak = typeof window !== 'undefined' ? require('keycloak-js') : null;

// export function initializeKeycloak(
//   keycloak: KeycloakService
//   ) {
//     if(Keycloak!==null){
//       return () =>   keycloak.init({
//         config: environment.keycloakConfig,
//         initOptions : {
//           checkLoginIframe: false
//         }
//       });
//     }else{
//       return ()=>{
//         return new Promise<Boolean>((resolve,reject)=>{
//           resolve(true);
//         });
//       }
//     }
// }


function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
  return () =>
    keycloak.init({
      config: environment.keycloakConfig,
      initOptions : {
        checkLoginIframe: false
      }
    });
}
@NgModule({
  declarations: [
    AppComponent,
    UniversComponent,
    HomeComponent,
    ProductPageComponent,
    CatalogueComponent,
    ProductEditComponent,
    CartPageComponent,
    ProductInventoryComponent,
    OrderDashboardComponent,
    UserDashboardComponent,
    SearchComponent,
    PromotionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    MatSidenavModule,
    HttpClientModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatIconModule,
    MatToolbarModule,
    MatListModule,
    MatButtonModule,
    InfiniteScrollModule,
    MatBadgeModule,
    MatDialogModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FontAwesomeModule,
    MatTooltipModule,
    ComponentModule,
    OrderModule,
    ],
  providers: [
    FormBuilder,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi   : true,
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
