import { formatDate } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as e from 'express';
import * as moment from 'moment';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatepickerComponent implements OnInit {

  @Input() locale: string;
  @Input() date: Date;
  @Input() slot: string;
  @Input() disabled = false;

  mormingSlot = '09:00\n  -\n12:00'
  afternoonSlot = '14:00\n  -\n18:00'
  isAfterNoon: boolean;
  formatedDate: string;


  @Output() emitSelectedDate = new EventEmitter<{date: Date, slot: string}>();

  isBellowNoon: boolean

  selectedDate: any;

  constructor() { }

  ngOnInit() {
    moment.locale(this.locale);
  }

  ngOnChanges() {
    if (this.date) {
      this.formatedDate =  new Date(this.date).toISOString().split('T')[0];
    }
    if (this.slot) {
      if (this.slot === this.mormingSlot) {
        this.isAfterNoon = false
      }
      else {
        this.isAfterNoon = true
      }
    }
  }

  selectDate(day: any){
      if(this.disabled)
        return
      this.date = new Date((day.target as HTMLInputElement).value)
      this.formatedDate = this.date.toISOString().split('T')[0];
      this.emitSelectedDate.emit({
        date: this.date,
        slot: this.slot
      });
  }


  selectSlot(slot: string){
    if(this.disabled)
      return
    this.slot = slot
    this.emitSelectedDate.emit({
      date: this.date,
      slot: this.slot
    });
  }
}