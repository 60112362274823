<section class="screen-margin">
  <app-breadcrumb [path]="path"></app-breadcrumb>

</section>
 <!-- Prodcut picures prices and actions such as add to cart  -->
<section class="product-overview screen-margin">
    <div class="slider">
      <img class="focused-picture" src="{{displayedImage?.url ? displayedImage?.url : '/assets/no-image.png'}}" alt="Placeholder image">
      <div class="slider-x"  [class.is-hidden]="!product?.images?.[1]">
        <img *ngFor="let image of product?.images" class="other-picture" (click)="changeImage(image)" src="{{image.url}}" alt="Placeholder image">
      </div>
    </div>
    <div class="product-info">
        <app-product-card [product]="product" [withPicture]="false"></app-product-card>
    </div>
</section>
<!-- Description section of the product -->
<section class="description screen-margin">
  <h3> Description </h3>
  <p [innerHTML]="product?.description"></p>
  <h3> Caractéristiques </h3>
  <table>
    <tbody>
      <tr *ngFor="let attribute of product?.attributes"><th *ngIf="attribute?.value">{{attribute?.label}}</th><td *ngIf="attribute?.value" class="pl-2">{{attribute?.value}}</td></tr>
    </tbody>
  </table>
</section>

<!-- Description section of the product -->
<section class="articles screen-margin" *ngIf="product?.articles?.length > 0">
    <h3> Produits inclus dans l'offre </h3>
    <app-article-list [articles]="product?.articles" [disabled]="true"></app-article-list>
</section>