import { Component, Input, OnInit } from '@angular/core';
import { Section, Component as gComponent } from 'gul-si-commons';

@Component({
  selector: 'app-basic-section',
  templateUrl: './basic-section.component.html',
  styleUrls: ['./basic-section.component.scss']
})
export class BasicSectionComponent  implements OnInit {
  ngOnInit(): void { }

  @Input() content: gComponent
}
