import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteComponent } from './steps/quote/quote.component';
import { OrderPageComponent } from './order.component';
import { ComponentModule } from 'src/app/core/component/component.module';
import { EditComponent } from './steps/edit/edit.component';
import { CoreModule } from 'keycloak-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfViewerModule } from 'ng2-pdf-viewer';



@NgModule({
  declarations: [
    QuoteComponent,
    OrderPageComponent,
    EditComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    ComponentModule,
    FontAwesomeModule,
    PdfViewerModule
  ],
  exports: [
    QuoteComponent,
    OrderPageComponent,
    EditComponent
  ]
})
export class OrderModule { }
