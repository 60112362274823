import { Component } from '@angular/core';
import { Order } from 'gul-si-commons';
import { AdminOrderService } from 'src/app/service/rest/admin-order.service';

@Component({
  selector: 'app-order-dashboard',
  templateUrl: './order-dashboard.component.html',
  styleUrls: ['./order-dashboard.component.scss']
})
export class OrderDashboardComponent {

  constructor (protected adminOrderService: AdminOrderService) {}

}
