import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ComponentTypes, Component as gComponent, Section, testContentPage } from "gul-si-commons"

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrls: ['./sections.component.scss']
})
export class SectionsComponent implements OnInit {

    sections: Section<gComponent>[];


    @ViewChild('fullScreenBanner') fullScreenBanner: TemplateRef<any>;
    @ViewChild('logoAndDescriptionCartbridge') logoAndDescriptionCartbridge: TemplateRef<any>;
    @ViewChild('imageAndDescriptionLeftRight') imageAndDescriptionLeftRight: TemplateRef<any>;
    @ViewChild('imageAndDescriptionRightLeft') imageAndDescriptionRightLeft: TemplateRef<any>;
    @ViewChild('cardList') cardList: TemplateRef<any>;
    @ViewChild('footer') footer: TemplateRef<any>;


    getTemplate(content: gComponent): TemplateRef<any> {
        return this[content.type]
    }
    
    ngOnInit() {
      let content = {
        title: 'Katharsis-Event',
        description: "Artisan des métiers de l’image, du son, de la lumière, de la structure scénique, de la distribution électrique et du management technique au service du spectacle vivant.\nPrestation de services      Location de matériel       Installation et maintenance",
        sections: [
            {title: "Katharsis-Event", 
            content : [{
                type: ComponentTypes.fullScreenBanner,
                imageUrls: ["https://katharsis-image.s3.fr-par.scw.cloud/cms/1.jpg"],
                title: "Katharsis-Event",
                description: ""}
            ]},
            {title: "Katharsis-Event", 
            content : [{
                type: ComponentTypes.logoAndDescriptionCartbridge,
                logoUrl: "https://katharsis-image.s3.fr-par.scw.cloud/Logo%20Katharsis%20%23991c1f.png",
                description: "Artisan des métiers de l’image, du son, de la lumière, de la structure scénique, de la distribution électrique et du management technique au service du spectacle vivant.\nPrestation de services      Location de matériel      Etude      Installation et maintenance"}
            ]},
            {title: "Prestation", 
    
            content : [{
                type: ComponentTypes.imageAndDescriptionLeftRight,
                imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/cms/3.jpg",
                title: "Prestation",
                description: "Notre équipe polyvalente fournit des prestations techniques qui répondent à vos besoins spécifiques. Que ce soit pour un concert, une soirée d'entreprise ou toute autre manifestation, nous mettons à profit notre savoir-faire pour créer une expérience unique. Notre objectif modeste est de travailler en collaboration avec vous afin de réaliser votre vision, en mettant l'accent sur la qualité et le professionnalisme."}
            ]},
            {title: "Location", 
            content : [{
                type: ComponentTypes.imageAndDescriptionRightLeft,
                imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/cms/6.jpg",
                title: "Location",
                description: "Katharsis-Event propose une gamme variée de matériel en location pour répondre à vos besoins techniques. Nos équipements audiovisuels, nos structures et nos solutions sont entretenus et constamment actualisés. Nous comprenons l'importance de la flexibilité et de la qualité, c'est pourquoi nous offrons des solutions de location adaptées à vos exigences, même les plus temporaires."}
            ]},
            {title: "Étude", 
            content : [{
                type: ComponentTypes.imageAndDescriptionLeftRight,
                imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/cms/4.jpg",
                title: "Étude",
                description: "Notre service d'études personnalisées est conçu pour vous offrir des solutions adaptées. Notre équipe d'ingénieurs se consacre à analyser attentivement chaque détail de votre événement, en veillant à créer des solutions sur mesure qui respectent vos contraintes. Faites-nous confiance pour traduire vos idées en une expérience captivante, tout en respectant votre budget et vos besoins spécifiques."}
            ]},
            {title: "Installation permanente", 
            content : [{
                type: ComponentTypes.imageAndDescriptionRightLeft,
                imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/cms/5.jpg",
                title: "Installation permanente",
                description: "L'étape cruciale de l'installation est abordée avec soin par nos équipes. Nous sommes conscients de l'importance de la sécurité et de la stabilité de chaque structure, et nous nous engageons à fournir des installations fiables. En travaillant de concert, nos experts en ingénierie à ce que tous les équipements soient intégrés de manière harmonieuse. Notre savoir faire se reflète dans notre approche sérieuse de l'installation, créant ainsi les bases d'une intégration réussi."}
            ]}
            // ,
            // {title: "Quelques chiffr", 
            // content : [{
            //     type: ComponentTypes.cardList,
            //     title: "Quelques chiffres",
            //     description: "Lorem ipsum dolor sit amet, ",
            //     cards: [{
            //         type: ComponentTypes.figureCard,
            //         imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/Logo%20Katharsis%20%23991c1f.png",
            //         title: "Clients",
            //         description: "suivis et accompagnés",
            //     },
            //     {
            //         type: ComponentTypes.figureCard,
            //         imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/Logo%20Katharsis%20%23991c1f.png",
            //         title: "References",
            //         description: "de quoi repondre a vos besoins",
            //     },
            //     {
            //         type: ComponentTypes.figureCard,
            //         imageUrl: "https://katharsis-image.s3.fr-par.scw.cloud/Logo%20Katharsis%20%23991c1f.png",
            //         title: "Partenaire",
            //         description: "Nous travaillons avec des partenaires de confiance partageant nos valeurs",
            //     }]}]
            //     },
    
            // {title: "Produits Phare", 
            // content : [{
            //     type: ComponentTypes.cardList,
            //     title: "Produits Phare",
            //     description: "Lpetites enceintes et connectiques ",
            //     cards: [{
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     },
            //     {
            //         type: ComponentTypes.productCard,
            //         productId: "1",
            //     }]}]
            //     }
            ]}
      this.sections = content.sections;
    }
  

}
