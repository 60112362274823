<ng-container *ngFor="let section of sections">
    <ng-container *ngFor="let content of section.content"> 
        <ng-container 
        *ngTemplateOutlet="getTemplate(content); context: {content : content}"></ng-container>
    </ng-container>
</ng-container>


<ng-template #fullScreenBanner let-content="content">
   <app-banner [content]="content"></app-banner>
 </ng-template>

 <ng-template #logoAndDescriptionCartbridge let-content="content">
   <app-cartbridge [content]="content"></app-cartbridge>
 </ng-template>

 <ng-template #imageAndDescriptionLeftRight let-content="content">
   <app-image-description [content]="content"></app-image-description>
 </ng-template>

 <ng-template #imageAndDescriptionRightLeft let-content="content">
   <app-description-image [content]="content"></app-description-image>
 </ng-template>

<ng-template #cardList let-content="content">
   <app-card-list [content]="content"></app-card-list>
 </ng-template>

<ng-template #footer let-content="content">
   <app-footer [content]="content"></app-footer>
 </ng-template>
