import { Component, Input } from '@angular/core';
import { BasicSectionComponent } from '../basic-section/basic-section.component';
import { ImageAndDescriptionRightLeft } from 'gul-si-commons';

@Component({
  selector: 'app-description-image',
  templateUrl: './description-image.component.html',
  styleUrls: ['./description-image.component.scss']
})
export class DescriptionImageComponent extends BasicSectionComponent {

  @Input() content : ImageAndDescriptionRightLeft
}
