import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Product } from 'gul-si-commons';
import { it } from 'node:test';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {
  @Input() control: FormControl
  @Input() placeholder: string
  @Input() results: Product[]
  @Output() selectResultEvent = new EventEmitter<Product>()


  selectResult(item: Product) {
    this.selectResultEvent.emit(item)
    this.results = []
  }
}
