<div class="wrapper">
    <div class="step" *ngFor="let step of steps">
        <div class="logo">
            <div [class.disabled]="compareWithCurrentStep(step) > 0"  class="circle">
                <fa-icon [icon]="step.icon"></fa-icon>
            </div>
            <div *ngIf="compareWithCurrentStep(step) < 0" class="badge success">
                <fa-icon [icon]="faCheck"></fa-icon>
            </div>
            <div *ngIf="compareWithCurrentStep(step) == 0"class="badge disabled">
                <fa-icon [icon]="faClock"></fa-icon>
            </div>
        </div>
        <p class="label">{{step.name}}</p>
    </div>
</div>