    <div class="">
        <div>
                <span><strong> {{customer?.firstName ? customer.firstName : "john"}}</strong></span>
                <span><strong> {{customer?.lastName ? customer.lastName : "Doe"}}<br></strong></span>
                <span>{{customer?.address?.street ? customer.address.street : "Rue de la paix"}}<br></span>
                <span>{{customer?.address?.zipcode ? customer.address.zipcode : "75005"}} </span>
                <span>{{customer?.address?.city ? customer.address.city : "Paris"}}<br></span>
                <span>{{customer?.address?.country ? customer.address.country : "France"}}<br><br></span>
                <i class="fa fa-envelope"></i><span> {{customer?.email ? customer.email : "john.doe@email.com"}}    </span><br>
                <i class="fa fa-phone"></i><span> {{customer?.cellularNumber ? customer.cellularNumber : "48 15 16 23 42"}}</span>
    </div>
</div>
<div *ngIf="!disabled" class="row">
    <app-cta-button class="edit" (click)="editBilling()" [text]="'Modifier'"></app-cta-button>
</div>
