import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { CustomerPickerComponent } from './customer-picker/customer-picker.component';
import { DatepickerComponent } from './date-picker/date-picker.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NotificationComponent } from './notification/notification.component';
import { OrderListComponent } from './order-list/order-list.component';
import { PriceComponent } from './price/price.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ResultListComponent } from './result-list/result-list.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SectionCardComponent } from './section-card/section-card.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { UserinfoEditComponent } from './userinfo-edit/userinfo-edit.component';
import { SectionsModule } from './sections/sections.module';
import { AddToCartButtonComponent } from './buttons/add-to-cart-button/add-to-cart-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PlusMinusQteComponent } from './plus-minus-qte/plus-minus-qte.component';
import { ButtonsModule } from './buttons/buttons.module';
import { FormModule } from './form/form.module';
import { TreeComponent } from './tree/tree.component';
import { ResultListAsListComponent } from './result-list-as-list/result-list-as-list.component';
import { SelectComponent } from './select/select.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { ScannerComponent } from './scanner/scanner.component';
import { InputModule } from './input/input.module';
import { StepperComponent } from './stepper/stepper.component';
import { ListComponent } from './list/list.component';
import { ListModule } from './list/list.module';



@NgModule({
  declarations: [
    CategorySelectorComponent,
    CustomerPickerComponent,
    DatepickerComponent,
    NavBarComponent,
    NotificationComponent,
    OrderListComponent,
    PriceComponent,
    ProductCardComponent,
    ResultListComponent,
    SearchBarComponent,
    SectionCardComponent,
    UploadFileComponent,
    UserinfoEditComponent,
    BreadcrumbComponent,
    PlusMinusQteComponent,
    TreeComponent,
    ResultListAsListComponent,
    SelectComponent,
    ArticleListComponent,
    ScannerComponent,
    StepperComponent,
  ],
  exports: [
    CategorySelectorComponent,
    CustomerPickerComponent,
    DatepickerComponent,
    NavBarComponent,
    NotificationComponent,
    OrderListComponent,
    PriceComponent,
    ProductCardComponent,
    ResultListComponent,
    SearchBarComponent,
    SectionCardComponent,
    UploadFileComponent,
    UserinfoEditComponent,
    SectionsModule,
    SectionsModule,
    ButtonsModule,
    InputModule,
    ListModule,
    BreadcrumbComponent,
    PlusMinusQteComponent,
    TreeComponent,
    ResultListAsListComponent,
    ArticleListComponent,
    ScannerComponent,
    StepperComponent,
  ],
  imports: [
    CommonModule,
    FontAwesomeModule,
    AppRoutingModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    ButtonsModule,
    FormModule,
    ZXingScannerModule,
    InputModule,
    ListModule,
  ]
})
export class ComponentModule { }
