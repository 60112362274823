import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-cta-button',
  templateUrl: './toggle-cta-button.component.html',
  styleUrls: ['./toggle-cta-button.component.scss']
})
export class ToggleCtaButtonComponent {

  @Input() textOff: string
  @Input() textOn: string
  isOn: boolean
  @Output() on = new EventEmitter()
  @Output() off = new EventEmitter()

  turnOff() {
    this.isOn = false
    this.off.emit()
  }

  turnOn() {
    this.isOn = true
    this.on.emit()
  }
}
