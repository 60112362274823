import { Component, OnInit } from '@angular/core';
import { BasicFormComponent } from '../basic-form/basic-form.component';
import { Attribute, TreeNode } from 'gul-si-commons';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { UtilService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-tree-node-form',
  templateUrl: './tree-node-form.component.html',
  styleUrls: ['./tree-node-form.component.scss']
})
export class TreeNodeFormComponent extends BasicFormComponent<TreeNode> implements OnInit{
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp

  ngOnInit(): void {
      super.ngOnInit()
  }

addValue() { 
  this.objectAsForm.controls.value.push(this.objectToForm(new Attribute()))
}

deleteValue(lessonIndex: number) {
  this.objectAsForm.controls.value.removeAt(lessonIndex);
}

moveUp(array: any, element: TreeNode) {
  let index = array.indexOf(element)
  new UtilService().ArrayMoveUp(array, index)
}

moveDown(array: any, element: TreeNode) {
let index = array.indexOf(element)
new UtilService().ArrayMoveDown(array, index)
}

}
