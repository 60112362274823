<div class="wrapper">
    <div class="input-wrapper">
        <input class="effect" type="text" [formControl]="control" placeholder="{{placeholder}}">
        <span class="focus-border"></span>
    </div>
    <div class="search-result">
        <div class="item" *ngFor="let item of results"  value="{{item.name}}" (click)="selectResult(item)">
            <img class="picture" src="{{item.images[0]?.url}}">
            <div class="name">{{item.name}}</div>
            <div class="price">{{item.offers[0].price.ht | number : '1.2-2'}}{{item.offers[0].price.currency}}</div>
        </div>
    </div>
</div>
