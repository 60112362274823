import { Component, Input, OnInit } from '@angular/core';
import { BasicSectionComponent } from '../basic-section/basic-section.component';
import { Section, FullScreenBanner } from 'gul-si-commons';
import { transition, trigger, style, keyframes, animate } from '@angular/animations';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  animations: [
    trigger('slide', [
      transition(':increment', [
        style({ transform: 'translateX(0%)' }),
        animate('1000ms ease-out', keyframes([
          style({ transform: 'translateX(0%)', offset: 0 }),
          style({ transform: 'translateX(-100%)', offset: 0.5 }),
          style({ transform: 'translateX(0%)', offset: 1 }),
        ])),
      ]),
      transition(':decrement', [
        style({ transform: 'translateX(0%)' }),
        animate('1000ms ease-out', keyframes([
          style({ transform: 'translateX(0%)', offset: 0 }),
          style({ transform: 'translateX(100%)', offset: 0.5 }),
          style({ transform: 'translateX(0%)', offset: 1 }),
        ])),
      ]),
    ]),
  ]
})
export class BannerComponent extends BasicSectionComponent implements OnInit {
  @Input() content: FullScreenBanner;
  slides = []
  currentIndex = 0;

  ngOnInit() {
    this.slides = this.content.imageUrls
    setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    }, 8000); // Change the interval time as needed (in milliseconds)
  }
}
