import {AfterViewInit, Component, Input, OnChanges, OnInit} from '@angular/core';
import {concat, isEqual, remove} from "lodash"
import {NotificationService} from "../../services/notification.service";
import {SimpleRestService} from "../../services/simple-rest.service";
import {ProductService} from "../../../service/rest/product.service";
import {CategoryService} from "../../../service/rest/category.service";
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthGuardService } from '../../services/auth/auth-guard.service';
import { FilterBuilder, Product } from 'gul-si-commons';

@Component({
  selector: 'app-result-list',
  templateUrl: './result-list.component.html',
  styleUrls: ['./result-list.component.scss']
})
export class ResultListComponent implements OnInit, OnChanges {

  @Input() client: SimpleRestService
  @Input() isSearchOnly: boolean = false
  @Input() filter?: FilterBuilder

  array: Product[] = [];
  page = 0;
  size = 10;
  throttle = 10;
  total = -1;
  scrollDistance = 1;
  scrollUpDistance = 2;
  direction = "";
  modalOpen = false;
  userInfo: any;


  constructor(private route: Router, protected notification: NotificationService, private userInfoService: AuthGuardService) {
  }

  ngOnInit() {
    this.userInfo = this.userInfoService.userInfo
  }

  redirect(productId)  {
    this.route.navigate(['/produit', productId])

  }

  ngOnChanges(change) {
    if(change.filter) {
      this.page = 0
    if(this.filter) {
      this.client.getFiltered(this.filter.build() as any, this.page, this.size).subscribe(next => {
        this.array = next.data;
        this.total = next.length
        },
        error => {
          this.notification.error('ERROR filters', error)
        })}
    }
  }

  onScrollDown(ev?: any) {
    if(this.total > 0 && this.page + 1  * this.size < this.total) {
    this.page += 1;
       this.client.getFiltered(this.filter, this.page, this.size).subscribe(next => {
            this.array = concat(this.array, next.data)
          },
          error => {
            this.notification.error('ERROR', error)
          }
        )
    }
  }
  

  create(): void {
    this.client.create().subscribe(next => {
    this.array.reverse()
    this.array.push(next.data.pop())
    this.array.reverse()
     this.notification.succes('OK', 'Produit cree')
      },           error => {this.notification.error('ERROR', error)}
    )
  }

  delete(id: string): void {
    this.client.delete(id).subscribe(next => {
        remove(this.array, (e) => e._id === id)
        this.notification.succes('OK', 'Produit supprime')
      },           error => {this.notification.error('ERROR', error)}
    )
  }
}