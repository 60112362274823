import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { FilterBuilder } from 'gul-si-commons';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ProductService } from 'src/app/service/rest/product.service';
import { TreeService } from 'src/app/service/rest/tree.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent {
  faChevronRight = faChevronRight
  faHome = faHome
  @Input() name
  filter: FilterBuilder = new FilterBuilder()

  constructor(public catalogue: ProductService, public tree: TreeService, private notification: NotificationService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let queryString = params['name'];
      this.name = queryString === undefined ? '' : queryString;

      this.filter.fieldEquals('name', queryString, true)
    })
  }
}
