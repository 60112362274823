import { Component, Input } from '@angular/core';
import { faPencil, IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: [
    './icon-button.component.scss',
    '../button.component.scss'
  ]
})
export class IconButtonComponent {

  @Input() icon: IconDefinition = faPencil
}
