import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {Filter, FilterBuilder, Page} from "gul-si-commons";
import {SimpleRestService} from "../../core/services/simple-rest.service";
import { groupBy, mapValues } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class StockService extends SimpleRestService {

  constructor(public http: HttpClient) {
    super(http, 'stock', ['logistic'])
  }


  getById(id: string, page = 1, limit = 10): Observable<Page<any>> {
    return this.http.get<Page<any>>(`${this.baseUrl}/productId/${id}?size=${limit}&page=${page}`);
  }

}
