import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {from, Observable} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  constructor(private keycloakService: KeycloakService, private cookieService: CookieService ) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>  {
    // convert promise to observable using 'from' operator
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    // if your getAuthToken() function declared as "async getAuthToken() {}"
    let authToken = await this.keycloakService.getToken();
    if (authToken != undefined)
      this.cookieService.set('authToken', authToken )
    else
      authToken = this.cookieService.get('authToken' )
    // if your getAuthToken() function declared to return an observable then you can use
    // await this.auth.getAuthToken().toPromise()

    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
        Tenant: environment.keycloakConfig.realm
      }
    });

    // Important: Note the .toPromise()
    return next.handle(authReq).toPromise();
  }
}
