<div class="search">
  <input class="input" (keydown.enter)="onKeyDownEvent()" (focus)="onFocusEvent(true)" (blur)="onFocusEvent(false)" placeholder="Rechercher" [formControl]="searchForm"/>
  <fa-icon class="icon" [icon]="faSearch" (click)="onKeyDownEvent()"></fa-icon> 
</div>
<div class="search-results" *ngIf="focused">
  <div class="item" *ngFor="let i of results" value="{{i.name}}" (mousedown)="onClickEvent({product: i, offer: i.offers[0], qte: 1})">
      <img class="picture" src="{{i.images[0]?.url}}">
      <div class="name">{{i.name}}</div>
      <div class="price">{{i.offers[0].price.ht | number : '1.2-2'}}{{i.offers[0].price.currency}} {{i.offers[0].type === 'rent' ? 'HT/jours' : 'HT'}}</div>
  </div>
</div>