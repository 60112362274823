    <section class="screen-margin">
        <!-- Customer information -->
        <app-stepper [currentStep]="order?.currentStep"></app-stepper>
    </section>
    <section class="screen-margin">
        <!-- Section containing two colum for product list and for cart summary     -->

        <ng-container *ngIf="!order?.currentStep ? true : order?.currentStep === OrderStepName.pending">
            <div class="main-content">
                <h1> Nouvelle commande </h1>
            </div>
            <app-edit [order]="order" [disableAction]="disableAction" (nextOrderStep)="nextOrderStep()"></app-edit>
        </ng-container>
        <ng-container *ngIf="order?.currentStep === OrderStepName.validate">
            <div class="main-content">
                <h1> Ajustement de la commande </h1>
            </div>
            <app-edit [order]="order" [disableAction]="authService.isUserInRole('order') ? false : disableAction" (nextOrderStep)="createQuoteAndNext()"></app-edit>
        </ng-container>
        <ng-container *ngIf="order?.currentStep === OrderStepName.quote">
            <div class="main-content">
                <h1> Validation du devis </h1>
            </div>
            <app-quote class="main-content" [order]="order"  (nextOrderStep)="adminOrderService.createQuote(order._id)">
            </app-quote>
            <div *ngIf="authService.isUserInRole('order')" class="row">
                <app-cta-button class="validate" (click)="previousOrderStep()" [text]="'Etape precedente'"></app-cta-button>
                <app-cta-button class="validate" (click)="nextOrderStep()" [text]="'Etape suivante'"></app-cta-button>
            </div>
        </ng-container>
        <ng-container *ngIf="order?.currentStep === OrderStepName.payment">
            <div class="main-content">
                <h1> Paiement </h1>
            </div>
            <div *ngIf="authService.isUserInRole('order')" class="row">
                <app-cta-button class="validate" (click)="previousOrderStep()" [text]="'Etape precedente'"></app-cta-button>
                <app-cta-button class="validate" (click)="nextOrderStep()" [text]="'Etape suivante'"></app-cta-button>
            </div>
        </ng-container>
        <ng-container *ngIf="order?.currentStep === OrderStepName.shipping">
            <div class="main-content">
                <h1> Livraison </h1>
            </div>
            <div *ngIf="authService.isUserInRole('order')" class="row">
                <app-cta-button class="validate" (click)="previousOrderStep()" [text]="'Etape precedente'"></app-cta-button>
                <app-cta-button class="validate" (click)="nextOrderStep()" [text]="'Etape suivante'"></app-cta-button>
            </div>
        </ng-container>
        <ng-container *ngIf="order?.currentStep === OrderStepName.bill">
            <div class="main-content">
                <h1> Facturation </h1>
            </div>
            <div *ngIf="authService.isUserInRole('order')" class="row">
                <app-cta-button class="validate" (click)="previousOrderStep()" [text]="'Etape precedente'"></app-cta-button>
                <app-cta-button class="validate" (click)="nextOrderStep()" [text]="'Etape suivante'"></app-cta-button>
            </div>
        </ng-container>


    </section>
