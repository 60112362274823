import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { CartSlot, OfferType, Product } from 'gul-si-commons';
import {CartService} from "../../../service/cart.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, OnChanges{

  @Input() product: Product
  @Input() withPicture = true
  selectedOffer


  constructor(public  cartService: CartService) { 

  }
  ngOnInit(): void {
    this.product = new Product().fromObject(this.product)
    this.selectedOffer = this.product.getOffer(environment.keycloakConfig.realm, OfferType.rent)
  }

  ngOnChanges(): void {
    this.product = new Product().fromObject(this.product)
    this.selectedOffer = this.product.getOffer(environment.keycloakConfig.realm, OfferType.rent)
  }
  selectOffer(offer) {
    this.selectedOffer = offer
  }


}
