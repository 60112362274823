<div class="actions-panel">
    <app-toggle-cta-button class="button" [textOff]="'Tout sélectionner'" [textOn]="'Tout déselectionner'" (on)="selectAll()" (off)="unselectAll()"></app-toggle-cta-button>
    <app-select  class="button" [states]="moveActionNames()" (select)="selectAction($event)"></app-select>
      <div class="button" (click)="performAction()"> <fa-icon [icon]="faRocket"></fa-icon> </div>
</div>  
<div class=""
     infinite-scroll
     [infiniteScrollDistance]="scrollDistance"
     [infiniteScrollUpDistance]="scrollUpDistance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onScrollDown()">

      <div class="">
        <div class="article" [class.selected]="isSelected(product?._id)" (click)="select(product?._id)" *ngFor="let product of array">
          <div class="wrapper">
            <div class="detail">
              <!-- Top-line -->
                <div class="top-line">
                    <div class="picture">
                        <img class="picture" [src]="product?.images[0]?.url ? product?.images[0]?.url : '/assets/no-image.png'" alt="Placeholder image">
                    </div>
                    <div class="description">
                        <p class="reference">Ref: {{product?.ean}}</p>
                        <h3 class="name">{{product?.name}}</h3>
                        <ng-container *ngFor="let offer of product?.offers">
                          <app-price *ngIf="offer.isVisible" [price]="offer?.price" [type]="offer.type"></app-price>
                        </ng-container>
                    </div>
                </div>
                <!-- Bottom line -->
                <div class="bottom-line">
                </div>
            </div>
            <div>
              <button class="edit-product" [routerLink]="['/produit',product._id, 'edit' ]"><fa-icon [icon]="faEdit"></fa-icon></button>
            </div>
          </div>
        </div>
      </div>
    <!-- Display "Aucun résultat" if no result -->
    <ng-container *ngIf="!array?.length">
      <div class="wrapper">
        <h3>Aucun résultat</h3>
      </div>
    </ng-container>
</div>


