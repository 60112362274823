import {Component, OnInit} from '@angular/core';
import {KeycloakService} from "keycloak-angular";
import {KeycloakProfile} from "keycloak-js";
import {ProductService} from "../../../service/rest/product.service";
import {FilterBuilder, Page, Product, Tree, TreeNode} from "gul-si-commons";
import {UtilService} from "../../../service/utils.service";
import {NotificationService} from "../../services/notification.service";
import {NodeService, TreeService} from "../../../service/rest/tree.service";
import {CartService} from "../../../service/cart.service";
import {AuthGuardService} from "../../services/auth/auth-guard.service";
import { faTruckFast, faBookOpen, faCartShopping, faSearch, faSignIn, faSignOut, faBars, faTruckMoving, faStore, faArrowLeft, faClose, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  faTruckFast = faTruckFast
  faBookOpen = faBookOpen
  faCartShopping = faCartShopping
  faSearch = faSearch
  faSignIn = faSignIn
  faSignOut = faSignOut
  faBars = faBars
  faStore = faStore
  faTruckMoving = faTruckMoving
  faArrowLeft = faArrowLeft
  faClose = faClose
  faChevronRight = faChevronRight

  constructor(public route: Router, public readonly keycloak: AuthGuardService, private notification: NotificationService,
              private treeService: TreeService, public catalogue: ProductService,
              public util: UtilService,public cart: CartService) {
  }

  
  public userProfile: KeycloakProfile | null = null;
  public tree: TreeNode;
  public rootNode: TreeNode
  public selectedNode: TreeNode
  public isLoggedIn: boolean
  public searchForm: any;
  public searchResult: Product[];
  public focusOnNavigation: boolean;
  public focusOnTree: boolean;



  async ngOnInit(): Promise<void> {
    this.keycloak.isLoggedIn().then(res => this.isLoggedIn = res)

    this.treeService.get().subscribe(o => {
        this.tree = o.data.pop().root;
        this.rootNode = this.tree;
      },
      error => {
        this.notification.error('ERROR', error)
      }
    )
    this.searchForm  = new FormControl('')
    this.searchForm.valueChanges.subscribe(change => {
      if(change.length >= 3) {
        let filter = new FilterBuilder().fieldEquals('name', change, true)
        this.catalogue.getFiltered(filter, 0, 10).subscribe(next => {
          this.searchResult = next.data;
          },
          error => {
            this.notification.error('ERROR filters', error)
          }
      )
      }
    })
  }

  selectnode(node: TreeNode) {
    this.selectedNode = node
  }

  onSelectSearchResultEvent(product: Product) {
    this.route.navigate(['/produit', product._id])
  }

  redirectTo(uri: string) {
    this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.route.navigate([uri])});
  }

  onKeyDownEvent() {
    this.focusOnNavigation = false
    this.redirectTo('/rechercher/' +this.searchForm.getRawValue())
  }

  onToggleMenuEvent() {
    this.focusOnTree = !this.focusOnTree
  }


  onFocusNavigationEvent(isFocused: boolean) {
    this.focusOnNavigation = isFocused
    if(isFocused === true)
      this.focusOnTree = false
  }

  public isUserInRole(roles: string) {
    return this.keycloak.isUserInRole(roles)
  }

  public login(): void {
    this.keycloak.login();
  }

  public logout(): void {
    this.keycloak.logout();
  }
}
