import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionsComponent } from './sections/sections.component';
import { CartbridgeComponent } from './cartbridge/cartbridge.component';
import { BannerComponent } from './banner/banner.component';
import { ImageDescriptionComponent } from './image-description/image-description.component';
import { DescriptionImageComponent } from './description-image/description-image.component';
import { FooterComponent } from './footer/footer.component';
import { CardListComponent } from './card-list/card-list.component';
import { BasicSectionComponent } from './basic-section/basic-section.component';



@NgModule({
  declarations: [
    SectionsComponent,
    CartbridgeComponent,
    BannerComponent,
    ImageDescriptionComponent,
    DescriptionImageComponent,
    FooterComponent,
    CardListComponent,
    BasicSectionComponent
  ],
  exports: [
    SectionsComponent,
    CartbridgeComponent,
    BannerComponent,
    ImageDescriptionComponent,
    DescriptionImageComponent,
    FooterComponent,
    CardListComponent
  ],
  imports: [
    CommonModule
  ]
})
export class SectionsModule { }
