import { Component, Input } from '@angular/core';
import { BasicSectionComponent } from '../basic-section/basic-section.component';
import { ImageAndDescriptionLeftRight } from 'gul-si-commons';

@Component({
  selector: 'app-image-description',
  templateUrl: './image-description.component.html',
  styleUrls: ['./image-description.component.scss']
})
export class ImageDescriptionComponent extends BasicSectionComponent {
  @Input() content : ImageAndDescriptionLeftRight

}
