import { Component } from '@angular/core';
import { BasicFormComponent } from '../basic-form/basic-form.component';
import { Promotion } from 'gul-si-commons';

@Component({
  selector: 'app-promotion-form',
  templateUrl: './promotion-form.component.html',
  styleUrls: ['./promotion-form.component.scss']
})
export class PromotionFormComponent extends BasicFormComponent<Promotion> {
  ngOnInit(): void {
    super.ngOnInit()
}

}
