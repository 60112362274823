import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../service/rest/product.service";
import {ActivatedRoute} from "@angular/router";
import {Page, Attribute} from "gul-si-commons";
import {CategoryService} from "../../service/rest/category.service";
import {NodeService, TreeService} from "../../service/rest/tree.service";
import {FormControl} from "@angular/forms";
import {remove} from "lodash"
import {NotificationService} from "../../core/services/notification.service";
@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {
  selectedNodeCategories: any;

  constructor(public catalogue: ProductService, public category: CategoryService, public tree: TreeService, public node: NodeService, public notification: NotificationService, private activatedRoute: ActivatedRoute) {
  }

  selectedMenu = 'produits'

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let value = params['service'];
      // Set wich tab to display with the url param
      if (value === 'produits')
        this.selectedMenu = value
      if (value === 'categories')
        this.selectedMenu = value
      if (value === 'arborescence')
        this.selectedMenu = value
    });
  }
}
