<div style="display: flex; flex-direction: row; align-items: center;">
    <div class="button" [routerLink]="['/catalogue']"> <i class="fa fa-arrow-left"></i></div>
    <h4>    Code promotion</h4>
</div>
<app-cta-button [text]="'Nouvelle promotion'" (click)="promotionService.newPromotion()"> </app-cta-button>
<app-list [service]="promotionService" [template]="promotionCard"></app-list>


<ng-template #promotionCard let-content="content">
    <div class="row">
        <h3>{{content?.code}}:</h3>
        <h4>-{{content?.percent}}%</h4>
        <app-icon-button [icon]="faPencil" (click)="edit(content)"></app-icon-button>
        <app-icon-button [icon]="faTrash" (click)="promotionService.remove(content)"></app-icon-button>
    </div>
</ng-template>