import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {SimpleRestService} from "../../core/services/simple-rest.service";
import { CartSlot, Contact, OfferType, Order } from "gul-si-commons";
import { NotificationService } from "src/app/core/services/notification.service";
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminOrderService extends SimpleRestService {

  constructor(private notification: NotificationService, public http: HttpClient) {
    super(http, 'order', ['oms', 'admin'])
  }

  save(order: Order) {
    this.update(order._id, order).subscribe((page) => {
      console.log(page)
    })
  }

  add(order: Order, cartSlot: CartSlot): void {
    if(!order) {
      this.notification.succes('INFO', 'vous devez etre authentifier pour modifier une commande')
      return
    }
    order.clientOrder.add(cartSlot)
  
    this.update(order._id, order).subscribe(o => {
      order = order.fromObject(o.data.pop())
      this.notification.succes('OK', 'produit ajouté au panier')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  remove(order: Order, cartSlot: CartSlot): Order {
    if(!order) {
      this.notification.succes('INFO', 'vous devez etre authentifier pour modifier une commande')
      return
    }
    order.clientOrder.remove(cartSlot)
    this.update(order._id, order).subscribe(o => {
      this.notification.succes('OK', 'produit supprimé de la commande')
      return order.fromObject(o.data.pop())
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  setCustomer(order: Order, customer: Contact): void {
    order.customer = customer;
    order.clientOrder.customer = customer;
    this.update(order._id, order).subscribe(event => {
      this.notification.succes('OK', 'Adresse de facturation sauvegardée')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }


  setStartDate(order: Order, event: any) {
    let startDate = {
      date :  new Date(event.date),
      slot :  event.slot,
    }
    order.clientOrder.articles.map(e =>  {e.startDate = startDate})
    order.clientOrder.endDate = startDate
    this.update(order._id, order).subscribe(event => {
      return
    })
  }

  
  setEndDate(order: Order, event: any) {
    let endDate = {
      date :  new Date(event.date),
      slot :  event.slot,
    }
    order.clientOrder.articles.map(e =>  {
      if (e.offer.type === OfferType.rent) {
        e.endDate = endDate
      }
    })
    order.clientOrder.endDate = endDate
    this.update(order._id, order).subscribe(event => {
      return
    })
  }

  
  async createQuote(orderId: String): Promise<void> {

  await this.http.post(`${this.baseUrl}/${orderId}/quote`, {}).subscribe(event => {
      this.notification.succes('OK', 'Devis généré avec succes')
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  getQuoteUrl(orderId: string): string {
    return `${this.baseUrl}/${orderId}/quote`
  }

  downloadQuote(orderId: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/${orderId}/quote`, { responseType: 'blob' }).pipe(
      map((res: Blob) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    );
  }
}