import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

import {MatDialog} from "@angular/material/dialog";
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { OrderService } from 'src/app/service/rest/order.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { Order, OrderStepName } from 'gul-si-commons';
import { AdminOrderService } from 'src/app/service/rest/admin-order.service';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderPageComponent implements OnInit {
  faTruckFast = faTruckFast
  isEditable: boolean = false
  OrderStepName = OrderStepName
  disableAction = true
  id: string;
  order: Order;

  constructor(
    private route: Router, protected authService: AuthGuardService,
    public dialog: MatDialog,
    protected orderService: OrderService, protected adminOrderService: AdminOrderService, public activatedRoute: ActivatedRoute,
    public notification: NotificationService) {
  }

   totalPrice

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['orderId'];
      if(this.authService.isUserInRole('order')) {
        this.adminOrderService.getById(this.id).subscribe(res => {
          this.order = new Order().fromObject(res.data.pop())
        })
      } else {
      this.orderService.getById(this.id).subscribe(res => {
        this.order = new Order().fromObject(res.data.pop())
      })
    }
    },
      error => {
        this.notification.error('ERREUR', error)
      });
  }


  async createQuoteAndNext(): Promise<void> {
  await this.adminOrderService.createQuote(this.order._id).then(async res => {
    await new Promise(resolve => {
        setTimeout(resolve, 2000);
      });
    await this.nextOrderStep()
  })
}
  nextOrderStep(): void {
    this.orderService.nextStep(this.order._id).subscribe((page) => {
      this.order = new Order().fromObject(page.data[0])
    })
  }

  previousOrderStep(): void {
    this.orderService.previousStep(this.order._id).subscribe((page) => {
      this.order = new Order().fromObject(page.data[0])
    })
  }
}
