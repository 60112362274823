import { Component } from '@angular/core';

@Component({
  selector: 'app-minus-button',
  templateUrl: './minus-button.component.html',
  styleUrls: ['./minus-button.component.scss']
})
export class MinusButtonComponent {

}
