import { Component, Input } from '@angular/core';
import { faCheck, faClock, faCreditCard, faFileInvoice, faFileSignature, faHandshake, faTruck } from '@fortawesome/free-solid-svg-icons';
import { OrderStepName } from 'gul-si-commons'
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  faCheck = faCheck
  faClock = faClock
  @Input() steps = [
    {
      name: OrderStepName.pending,
      icon: faClock
    },
    {
      name: OrderStepName.validate,
      icon: faHandshake
    },
    {
      name: OrderStepName.quote,
      icon: faFileSignature
    },
    {
      name: OrderStepName.payment,
      icon: faCreditCard
    },
    {
      name: OrderStepName.shipping,
      icon: faTruck
    },
    {
      name: OrderStepName.bill,
      icon: faFileInvoice
    }
  ]

  @Input() currentStep = 'Paiement'

  compareWithCurrentStep(step) {
    let stepPosition = this.steps.findIndex(e => e.name === step.name)
    let currentStepPosition =  this.steps.findIndex(e => e.name === this.currentStep)
    if(this.currentStep === OrderStepName.closed)
      return -1
    if(currentStepPosition === -1)
      if(stepPosition === 0)
        return 0
      else 
        return 1
    else 
      return  stepPosition - currentStepPosition
  }
}
