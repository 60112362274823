<div class="card">
    <div class="wrapper">
      <div [class.hidden]="!withPicture"  class="picture">
        <img class="picture" [routerLink]="['/produit',product._id]" src="{{product?.images[0]?.url ? product?.images[0]?.url : '/assets/no-image.png'}}" alt="Placeholder image">
      </div>   
      <h2 class="name"  [routerLink]="['/produit',product._id]">{{product.name}}</h2>
    </div>
    <div class="tabs">
      <ng-container  *ngFor="let offer of product.offers">
        <div *ngIf="offer.isVisible" class="tab" (click)="selectOffer(offer)" [class.selected]="selectedOffer?._id === offer._id"><p>{{offer.type === "rent" ? "Location" : "Achat"}}</p></div>
      </ng-container>
    </div>
    <ng-container  *ngFor="let offer of product.offers">
      <div class="recap" *ngIf="selectedOffer?._id === offer._id && offer.isVisible">
        <div class="row">
          <app-price [routerLink]="['/produit',product._id]" [price]="offer.price" [type]="offer.type"></app-price>
          <app-plus-minus-qte [quantity]="cartService.productQuantity(product, offer)" (minusTrigger)="cartService.remove({product, offer: offer, qte: 1})" (plusTrigger)="cartService.add({product, offer: offer, qte: 1})"></app-plus-minus-qte>
        </div>
        <app-add-to-cart-button class="cart-add" (click)="cartService.add({product, offer: offer, qte: 1})"></app-add-to-cart-button>
      </div>
    </ng-container>
</div>
