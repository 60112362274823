
<div class="wrapper">
    <div class="stock">
        <div class="line" *ngFor="let unit of stock">
            <app-minus-button (click)="delete(unit._id)"></app-minus-button>
            <fa-icon [icon]="faBarcode"></fa-icon> <h5>{{unit.sku}}</h5>
        </div>
    </div>
    <div class="scanner">
        <zxing-scanner *ngIf="scannerDisplay" (scanSuccess)="scanResult($event)" [formats]="['QR_CODE', 'EAN_13', 'CODE_128', 'DATA_MATRIX']"></zxing-scanner>
       
        <div *ngIf="lastMatchedScan" class="matched-scan">
            <fa-icon [icon]="faBarcode" [size]="'5x'"></fa-icon>
            <h5>{{lastMatchedScan}}</h5>
            <div class="action-button"> 
                <app-cta-button [text]="'Ajouter'" (click)="publish()"></app-cta-button>
                <app-cta-button [text]="'Recommencer'" (click)="openCamera()"></app-cta-button>
            </div>
        </div>
        
        <div *ngIf="!lastMatchedScan" class="new-scan">
            <fa-icon [icon]="faCamera"  [size]="'5x'"></fa-icon>
            <app-cta-button [text]="'Ajouter un codebare'" (click)="openCamera()"></app-cta-button>
        </div>
    </div>
</div>
    
    