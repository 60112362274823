import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { UserInfo } from 'gul-si-commons';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {CookieService} from "ngx-cookie-service";
import { UserInfoService } from 'src/app/service/rest/user-info.service';



export function  unCypher(token: string): any {

  return JSON.parse(atob(token.split('.')[1]).toString());
}

export function isExpired(token: string) {
  let expitationDate = unCypher(token).exp
  return Date.now() < expitationDate
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService extends KeycloakAuthGuard {

  token: string
  userInfo: UserInfo

  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    private userInfoService: UserInfoService,
    private cookieService: CookieService
  ) {
    super(router, keycloak);
    this.token = this.cookieService.get('authToken' )
    // retry loggin in case of not expirer token and no loggin data.
    // THis case occur when your force reload the page
    // if (this.token) {
    //   this.isLoggedIn().then(r => {
    //     if (!r &&  !isExpired(this.token))
    //       this.login()
    //   })
    // }
    this.userInfoService.getById('self').subscribe(e => { this.userInfo = e.data[0]})
  }



  logout(): void {
    this.keycloak.logout();
  }

  login(): void {
    this.keycloak.login();
  }
  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.login();
    }

    // Get the roles required from the route.
    const requiredRoles = route.data.roles;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    return requiredRoles.every((role) => this.roles.includes(role));
  }

  public isUserInRole(role: string, ressource?: string) {
    return this.keycloak.isUserInRole(role, ressource)
  }


  public isLoggedIn() {
    return this.keycloak.isLoggedIn()
  }

}
