import { Component, Input } from '@angular/core';
import { faBarcode, faCamera } from '@fortawesome/free-solid-svg-icons';
import { StockUnit } from 'gul-si-commons';
import { StockService } from 'src/app/service/rest/stock.service';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent {
  faCamera = faCamera
  faBarcode = faBarcode
  @Input() productId: string
  lastMatchedScan: string = ''
  scannerDisplay: boolean = false
  stock: StockUnit[]

    constructor(private stockService: StockService) {
    }

  ngOnInit() {
    this.stockService.getById(this.productId,0,100).subscribe(o => {
      this.stock = o.data
    })
  }  

  scanResult(res) {
    this.lastMatchedScan = res
    this.scannerDisplay = false
  }

  openCamera() {
    this.scannerDisplay = true
  }

  delete(id) {
    this.stockService.delete(id).subscribe((o) => {
      this.stock.splice(this.stock.findIndex(u => u._id === id),1)
    })

  }

  publish() {
    let stock = new StockUnit()
    stock.productId = this.productId
    stock.sku = this.lastMatchedScan
    this.stockService.post([stock]).subscribe((o) => {
      this.lastMatchedScan = ''
      this.stock.push(o.data.pop())
    })
  }

}
