import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { OfferType, Price } from 'gul-si-commons';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnChanges {

  @Input() price: Price
  @Input() percentPromotion = 0
  @Input() type: OfferType
  currency
  referencePrice
  computedPrice
  
  ngOnChanges(): void {
    this.price = new Price().fromObject(this.price)
    this.currency = this.price?.currency
    this.referencePrice = new Price().fromObject(this.price).ht
    this.computedPrice = new Price().fromObject(this.price).mul(1-this.percentPromotion).ht

  }

}
