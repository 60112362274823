import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() states: string[] = ['Actions...'];

  form = new FormGroup({
    state: new FormControl(this.states[0]),
  });

  @Output() select = new EventEmitter<string>()


  constructor() {
    this.form.valueChanges.subscribe((v)=> { this.select.emit(v.state); return v})
  }
}
