<div class=""
     infinite-scroll
     [infiniteScrollDistance]="scrollDistance"
     [infiniteScrollUpDistance]="scrollUpDistance"
     [infiniteScrollThrottle]="throttle"
     (scrolled)="onScrollDown()">

  <ng-container *ngIf="!isSearchOnly">
      <div class="wrapper">
        <app-product-card *ngFor="let i of array" class="item" [product]="i"></app-product-card>
      </div>
    <!-- Display "Aucun résultat" if no result -->
    <ng-container *ngIf="!array?.length">
      <div class="wrapper">
      </div>
    </ng-container>
  </ng-container>
</div>



