import {Component, OnChanges, OnInit} from '@angular/core';
import {OrderService} from "../../service/rest/order.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../core/services/notification.service";
import {PromotionType, Address, Contact, Cart, Price} from "gul-si-commons";
import {CartService} from "../../service/cart.service";
import {MatDialog} from "@angular/material/dialog";
import {UserinfoEditComponent} from "../../core/component/userinfo-edit/userinfo-edit.component";
import { FormGroup, FormControl } from '@angular/forms';
import { chain, clone } from 'lodash';
import {MatDatepickerInputEvent, MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { faCheck, faTrash, faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { UserAddressFormComponent } from 'src/app/core/component/form/user-address-form/user-address-form.component';
import { AddToCartButtonComponent } from 'src/app/core/component/buttons/add-to-cart-button/add-to-cart-button.component';
import { PromotionService } from 'src/app/service/rest/promotion.service';
import { error } from 'console';

@Component({
  selector: 'app-order',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartPageComponent implements OnInit {
  faTruckFast = faTruckFast
  faCheck = faCheck
  faTrash = faTrash
  isEditable: boolean = false

  id: string
  promotion: boolean;
  codePromotionControl = new FormControl('')

  constructor(
    private route: Router, public authService: AuthGuardService,
    public dialog: MatDialog, public cartService: CartService,
    public orderService: OrderService, public activatedRoute: ActivatedRoute,
    public notification: NotificationService, public promotionService: PromotionService) {
  }

   totalPrice

  ngOnInit(): void {
  }

  addPromotion(code: string) {
    this.promotionService.getCode(code).subscribe((promotion) => {this.cartService.addPromotion(promotion.data.pop())}, error => this.notification.error('ERROR', error)    )
  }
}
