import {Component, Input, OnInit} from '@angular/core';
import {ProductService} from "../../../service/rest/product.service";
import {ActivatedRoute} from "@angular/router";
import {NodeService, TreeService} from "../../../service/rest/tree.service";
import {NotificationService} from "../../../core/services/notification.service";
import { FilterBuilder, TreeNode } from 'gul-si-commons';

@Component({
  selector: 'app-univers',
  templateUrl: './univers.component.html',
  styleUrls: ['./univers.component.scss']
})
export class UniversComponent implements OnInit {



  @Input() name
  filter: FilterBuilder
  path: TreeNode[];
  node: TreeNode;

  constructor(public catalogue: ProductService, public tree: TreeService,  public nodeService: NodeService, private notification: NotificationService, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      let first = params['first'];
      let second = params['second'];
      let third = params['third'];
      this.nodeService.post([first, second, third])
      .subscribe(
        async (o) => {
          this.node = o.data[o.data.length -1]
          this.path = o.data
          // Give the activated route param as
          let filter = new FilterBuilder()
          filter =  filter.fieldEquals('associatedNode', this.node._id)
          this.filter = filter
          this.name = this.node.label
      },
        error => {this.notification.error('ERROR', error)})
    });
  }

}
