import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {SimpleRestService} from "../../core/services/simple-rest.service";
import { Page, Promotion, PromotionType } from "gul-si-commons";
import { concat } from "lodash";
import { NotificationService } from "src/app/core/services/notification.service";

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends SimpleRestService {
  items: Promotion[] = [];

  size = 100;

  constructor(public http: HttpClient, protected notification: NotificationService) {
    super(http, 'promotion', [])
  }

  new() {
    return {type: PromotionType.PERCENT_DISCOUNT_CODE, code: 'MONCODE0POURCENT', percent: 0}
  }


  async newPromotion() {
    await super.create().subscribe(page => {
      this.items = concat(page.data, this.items)
    })
  }

  
  next(ev?: any) {
    console.log('scroll', ev)
    if(this.total > 0 && this.page + 1  * this.size < this.total) {
      this.page += 1;
         this.get( this.page, this.size).subscribe(next => {
              this.items = concat(this.items, next.data.map(o => { return new Promotion().fromObject(o)}))
            },
            error => {
              this.notification.error('ERROR', error)
            }
          )
      }
  }

  async save(promotion: Promotion) {
    await this.update(promotion._id, promotion).subscribe((page)=> {
      let promotion = page.data.pop()
      this.items[this.items.findIndex((p)=> {return p._id === promotion._id})] = promotion
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  async remove(promotion: Promotion) {
    await this.delete(promotion._id).subscribe((page)=> {
      this.items.splice(this.items.findIndex((p)=> {return p._id === promotion._id}), 1)
    },
    error => {
      this.notification.error('ERROR', error)
    })
  }

  getCode(value: string) {
    return this.getByField(['code'], value)
  }
}
