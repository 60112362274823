<ng-container>
  <div  class="nav-bar">
    <div class="navigation" [ngClass]="{'focused': focusOnTree}" >
      <div class="shortcut">
        <img class="brand"  [routerLink]="'/'" src="/assets/logo.png">
        <div *ngIf="isUserInRole('office')" class="logo" [routerLink]="'/catalogue'" id="to-shop">
          <fa-icon [icon]="faStore"></fa-icon>
          <p class="label">Mon magasin</p>
        </div>
        <div class="logo" (click)="onToggleMenuEvent()" id="to-menu">
          <fa-icon [icon]="faBars"></fa-icon>
          <p class="label">Menu</p>
        </div>
      </div>
      <div class="search">
        <input class="input" (keydown.enter)="onKeyDownEvent()" (focus)="onFocusNavigationEvent(true)" (blur)="onFocusNavigationEvent(false)" placeholder="Rechercher" [formControl]="searchForm"/>
        <fa-icon class="icon" [icon]="faSearch" (click)="onKeyDownEvent()"></fa-icon> 
      </div>
      <div class="shortcut">
        <div *ngIf="isLoggedIn" class="logo" [routerLink]="'/commandes'" id="to-order">
          <fa-icon [icon]="faTruckMoving"></fa-icon>
          <p class="label">Mes commandes</p>
        </div>
        <div class="logo" [routerLink]="'/panier'" id="to-cart">
          <fa-icon [icon]="faCartShopping"></fa-icon>
          <p class="label">Mon panier</p>
          <div class="badge primary">{{cart.totalCount()}}</div>
        </div>
        <div *ngIf="!isLoggedIn" class="logo" (click)="login()" id="login">
          <fa-icon [icon]="faSignIn"></fa-icon>
          <p class="label">Me connecter</p>
        </div>
        <div *ngIf="isLoggedIn" class="logo" (click)="logout()" id="logout">
          <fa-icon [icon]="faSignOut"></fa-icon>
        </div>
      </div>

    </div>
    <div class="search-results"  [ngClass]="{'focused': focusOnNavigation}">
      <div class="item" *ngFor="let i of searchResult" value="{{i.name}}" (mousedown)="onSelectSearchResultEvent(i)">
          <img class="picture" src="{{i.images[0]?.url}}">
          <div class="name">{{i.name}}</div>
          <div class="price">{{i.offers[0].price.ht | number : '1.2-2'}}{{i.offers[0].price.currency}}</div>
      </div>
    </div>
    <div class="tree-wrapper"  [ngClass]="{'focused': focusOnTree === true}"> 
      <div class="tree" >
        <div *ngFor="let node of rootNode?.childs" class="tree-node" >
          <div *ngIf="node.childs.length" class="with-sub-node" (click)="selectnode(node)"><h2>{{node.label}}</h2><fa-icon [icon]="faChevronRight"></fa-icon></div>
          <div *ngIf="!node.childs.length"  [routerLink]="util.pathFromTreeNodeArray([node])" class="with-sub-node"><h2>{{node.label}}</h2></div>
        </div>
      </div>
      <div *ngIf="selectedNode" class="tree sub-tree" >
        <div class="return-bar">
          <fa-icon class="logo" [icon]="faArrowLeft" (click)="selectnode(null)"></fa-icon>
          <h2> {{selectedNode.label}} </h2>
          <fa-icon class="logo" [icon]="faClose" (click)="onToggleMenuEvent()"></fa-icon>
        </div>
        <div *ngFor="let subNode of selectedNode?.childs" (click)="onToggleMenuEvent()">
            <div  [routerLink]="util.pathFromTreeNodeArray([selectedNode, subNode, subsubNode])" class="tree-node">
              <h3>{{subNode.label}}</h3>
            </div>
            <div *ngFor="let subsubNode of subNode?.childs" [routerLink]="util.pathFromTreeNodeArray([selectedNode, subNode, subsubNode])" class="tree-node sub-tree-node">
              <p>{{subsubNode.label}}</p>
            </div>
        </div>
      </div>
      <div class="out" (click)="onToggleMenuEvent(); selectnode(null) ">

      </div>
    </div>
  </div>
</ng-container>
