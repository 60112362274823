import { Component } from '@angular/core';
import { BasicFormComponent } from '../basic-form/basic-form.component';
import { Contact } from 'gul-si-commons';

@Component({
  selector: 'app-user-address-form',
  templateUrl: './user-address-form.component.html',
  styleUrls: ['./user-address-form.component.scss']
})
export class UserAddressFormComponent extends BasicFormComponent<Contact>{
  ngOnInit(): void {
      super.ngOnInit()
  }

}
