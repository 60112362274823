import {Component, OnInit} from '@angular/core';
import {NotificationService} from "./core/services/notification.service";
import {CartService} from "./service/cart.service";
import { ProductService } from './service/rest/product.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(public notification: NotificationService, public catalogue: ProductService) {

  }

  ngOnInit() {
  }
}
