import { Component, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Promotion } from 'gul-si-commons';
import { userInfo } from 'os';
import { PromotionFormComponent } from 'src/app/core/component/form/promotion-form/promotion-form.component';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AdminUserService } from 'src/app/service/rest/admin-user.service copy';
import { PromotionService } from 'src/app/service/rest/promotion.service';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent {

  faPencil = faPencil
  faTrash = faTrash

  @ViewChild('promotionCard') promotionCard: TemplateRef<any>;

  constructor(public promotionService: PromotionService, public dialog: MatDialog, public notification: NotificationService ) {

  }


  edit(promotion: Promotion) {
    const dialogRef = this.dialog.open(PromotionFormComponent,{
      data: {
        object: promotion,
      },
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        console.log(result)
        this.promotionService.save(result)
      }
    },
    error => {this.notification.error('ERROR', error)});
  }
}
