import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Order } from 'gul-si-commons';
import { AdminOrderService } from 'src/app/service/rest/admin-order.service';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent {

  @Input() order: Order
  @Input() client: Order
  @Output() nextOrderStep = new EventEmitter()

  constructor(protected adminOrderService: AdminOrderService) {}

  downloadQuote() {
    const pdfName = 'downloaded-file.pdf';

    this.adminOrderService.downloadQuote(this.order._id).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = pdfName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }
}
