import {Component, OnInit} from '@angular/core';
import {ProductService} from "../../../service/rest/product.service";
import {ActivatedRoute} from "@angular/router";
import {FormControl} from "@angular/forms";
import {NotificationService} from "../../../core/services/notification.service";
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { CartService } from 'src/app/service/cart.service';
import { Image, Price, Product, TreeNode } from 'gul-si-commons';
import { NodeService } from 'src/app/service/rest/tree.service';
import { StockService } from 'src/app/service/rest/stock.service';

@Component({
  selector: 'app-product-page',
  templateUrl: './product-page.component.html',
  styleUrls: ['./product-page.component.scss']
})
export class ProductPageComponent implements OnInit {
  private productId: string;

  product : Product
  displayedImage: Image
  path: TreeNode[]
  stock = 0
  constructor(private stockService: StockService, private catalogue: ProductService, private notification: NotificationService,public cartService: CartService, private activatedRoute: ActivatedRoute, public userInfoService: AuthGuardService, public nodeService: NodeService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.productId = params['productId'];
      this.stockService.getById(this.productId).subscribe(o => this.stock = o.length)
      this.catalogue.getById(this.productId).subscribe((o) => {
        let res = o.data.pop()
        res = new Product().fromObject(res)
        res.articles =  res.articles.map( a => { a.product = new Product().fromObject(a.product); return a})
        this.product = res
        this.displayedImage = this.product.images[0]
        this.nodeService.path(this.product.associatedNode).subscribe(o => {o.data.splice(0,1); this.path = o.data } )
       
      },
      error => {
        this.notification.error('ERREUR', error)
      }
    )
    },
      error => {
        this.notification.error('ERREUR', error)
      });
  
  }

  ngOnChanges() {
    this.ngOnInit()
  }

  changeImage(image: Image) {
    this.displayedImage = image
  }
}
