<div class="article-list">
    <div class="article" *ngFor="let line of articles">

        <div class="detail">
            <!-- Top-line -->
            <div class="top-line">
                <div class="picture">
                    <img class="picture" src="{{line?.product?.images[0]?.url ? line?.product?.images[0]?.url : '/assets/no-image.png'}}" [routerLink]="['/produit', line?.product?._id ]" alt="{{line?.product?.name}}">
                </div>
                <div class="description">
                    <!-- <p class="reference">Ref: {{line?.product?.ean}}</p> -->
                    <h3 class="name" [routerLink]="['/produit', line?.product?._id ]">{{line?.product?.name}}</h3>
                </div>
            </div>
            <!-- Bottom line -->
            <div class="bottom-line">
                <app-plus-minus-qte *ngIf="!disabled" class="quantity" 
                    [quantity]="line?.qte" 
                    (minusTrigger)="minusTrigger({product: line.product, offer: line.offer, qte: 1})"
                    (plusTrigger)="plusTrigger({product: line.product, offer: line.offer, qte: 1})">
                </app-plus-minus-qte>
                <h4 *ngIf="disabled"> Quantitée : {{line?.qte}}</h4>
                <app-price class="price" [price]="line?.offer?.price?.mul(line.qte)"></app-price>
            </div>
        </div>
    </div>
</div>