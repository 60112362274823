import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {Contact} from "gul-si-commons"
import { UserAddressFormComponent } from '../form/user-address-form/user-address-form.component';
@Component({
  selector: 'app-customer-picker',
  templateUrl: './customer-picker.component.html',
  styleUrls: ['./customer-picker.component.scss']
})
export class CustomerPickerComponent { 
  @Input() disabled = true
  @Input() customer: Contact

  @Output() emitSave = new EventEmitter()

  constructor(public dialog: MatDialog) {}


  editBilling() {
    const dialogRef = this.dialog.open(UserAddressFormComponent,{
      data: {
        object: this.customer,
      },
      panelClass: 'custom-dialog',
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.customer.fromObject(result)
        this.emitSave.emit()
      }
    });
  }
}
