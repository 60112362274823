import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {SimpleRestService} from "../../core/services/simple-rest.service";
import {Order, Page} from "gul-si-commons";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OrderService extends SimpleRestService {

  constructor(public http: HttpClient) {
    super(http, 'order', ['oms'])
  }

  new(): any {
    return {label: 'New'}
  }

  nextStep(orderId): Observable<Page<Order>> {
    return this.http.post<Page<Order>>(`${this.baseUrl}/${orderId}/nextStep`, [])
  }

  previousStep(orderId): Observable<Page<Order>> {
    return this.http.post<Page<Order>>(`${this.baseUrl}/${orderId}/previousStep`, [])
  }
}
