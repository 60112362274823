import {environment} from "../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {Filter, FilterBuilder, Page} from "gul-si-commons";
import {SimpleRestService} from "../../core/services/simple-rest.service";
import { groupBy, mapValues } from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ProductService extends SimpleRestService {

  constructor(public http: HttpClient) {
    super(http, 'product', ['catalogue'])
  }

  new(associatedNode = ''): any {
    return {
      name: 'New',
      description: 'Non renseigné',
      attributes: [],
      images: [],
      associatedNode: associatedNode,
      offers: [{
        seller: 'katharsis',
        type: 'rent',
        price:
          {
            ht: 999,
            TVA: 20,
            currency: '€'
          },
          isVisible: false
      },
      {
        seller: 'katharsis',
        type: 'sell',
        price:
          {
            ht: 999,
            TVA: 20,
            currency: '€'
          },
          isVisible: false
      }]
    };
  }

  queryOrFromFilters(filters: any[]) {
    let grouped = {$or : filters?.map(e => {let o = {}; o[e.code] = e.value;  return o})}
    return grouped
  }

  queryAndFromFilters(filters: any[]) {
    let grouped = {$and : filters?.map(e => {let o = {}; o[e.code] = e.value;  return o})}
    return grouped
  }

  getFiltered(filter: FilterBuilder, page = 1, limit = 10): Observable<Page<any>> {
    return this.http.post<Page<any>>(`${this.baseUrl}/filtered?size=${limit}&page=${page}`, filter);
  }
  //TODO clean
  setAssociatedNode(productIds: string[], value:string) {
    return this.http.post<Page<any>>(`${this.baseUrl}/associateNode/${value}`, productIds.map((e) =>{ return {_id: e}}))
  }

  createAndAssociate(associatedNodeId: string): Observable<Page<any>> {
    return this.http.post<Page<any>>(`${this.baseUrl}`, [this.new(associatedNodeId)]);
  }

  updateAssociatedNode(from: string, to: string): Observable<Page<any>> {
    return this.http.put<Page<any>>(`${this.baseUrl}/associatedNode/${from}/${to}`, []);
  }
}
