import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import { Order, OrderStepName, Promotion, PromotionType } from 'gul-si-commons';
import { AuthGuardService } from 'src/app/core/services/auth/auth-guard.service';
import { AdminOrderService } from 'src/app/service/rest/admin-order.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  faTruckFast = faTruckFast
  OrderStepName = OrderStepName

  @Input() order: Order
  @Input() disableAction = true;

  @Output() nextOrderStep = new EventEmitter()

  commercialDiscountControl = new FormControl(0)

  constructor(protected authService: AuthGuardService, protected adminOrderService: AdminOrderService) {
  }
  ngOnInit(): void {
    this.commercialDiscountControl.setValue(this?.order?.clientOrder?.promotions?.commercial?.percent)
    this.commercialDiscountControl.valueChanges.subscribe(
      v => { 
        this.order.clientOrder.promotions.commercial = new Promotion().fromObject({type: PromotionType.PERCENT_COMMERCIAL, code: 'VALIDATION_STEP', percent: Number(v)}) 
        this.adminOrderService.save(this.order)
      }
    )
  }


}
