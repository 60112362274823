import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CartSlot, FilterBuilder, Offer, Product } from 'gul-si-commons';
import { OrderService } from 'src/app/service/rest/order.service';
import { ProductService } from 'src/app/service/rest/product.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  faSearch = faSearch
  searchForm = new FormControl('')
  @Output() clickEvent = new EventEmitter<any>()
  results =  []
  focused = false

  constructor(private orderService: ProductService, private notification: NotificationService) {}

  ngOnInit() {
    this.searchForm  = new FormControl('')
    this.searchForm.valueChanges.subscribe(change => {
      if(change.length >= 3) {
        let filter = new FilterBuilder().fieldEquals('name', change, true)
        this.orderService.getFiltered(filter, 0, 10).subscribe(next => {
          this.results = []
          next.data.forEach(product => {
            product.offers.forEach(
              offer => {
                let unwindedProduct = new Product().fromObject(product)
                unwindedProduct.offers = [new Offer().fromObject(offer)]
                this.results.push(unwindedProduct)
              }
            )
          });
          },
          error => {
            this.notification.error('ERROR filters', error)
          })
      }
    })
  }

  onKeyDownEvent() {
    this.focused = false
  }

  onClickEvent(value: CartSlot) {
    this.searchForm.setValue(value.product.name)
    this.focused = false
    this.orderService.getById(value.product._id).subscribe( res => {
      value.product = new Product().fromObject(res.data.pop())
      this.clickEvent.emit(value)
    })
  }

  onFocusEvent(isFocused: boolean) {
    this.focused = isFocused
  }
}
