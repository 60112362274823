import { Component } from '@angular/core';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: [
    './add-to-cart-button.component.scss',
    '../button.component.scss'
  ]
})
export class AddToCartButtonComponent {
  faCartShopping = faCartShopping
}
