import { Component } from '@angular/core';
import { BasicSectionComponent } from '../basic-section/basic-section.component';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent extends BasicSectionComponent  {

}
