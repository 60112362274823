import { Component, Input } from '@angular/core';
import { BasicSectionComponent } from '../basic-section/basic-section.component';
import { LogoAndDescriptionCartbridge } from 'gul-si-commons';

@Component({
  selector: 'app-cartbridge',
  templateUrl: './cartbridge.component.html',
  styleUrls: ['./cartbridge.component.scss']
})
export class CartbridgeComponent extends BasicSectionComponent {

  @Input() content: LogoAndDescriptionCartbridge;

}
