import { Component } from '@angular/core';
import { Order } from 'gul-si-commons';
import { OrderService } from 'src/app/service/rest/order.service';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent {


  constructor(protected orderService: OrderService) {}

}
