import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ResultListComponent } from '../result-list/result-list.component';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { AuthGuardService } from '../../services/auth/auth-guard.service';
import { faEdit, faRocket } from '@fortawesome/free-solid-svg-icons';
import { SimpleRestService } from '../../services/simple-rest.service';
import { ProductService } from 'src/app/service/rest/product.service';

@Component({
  selector: 'app-result-list-as-list',
  templateUrl: './result-list-as-list.component.html',
  styleUrls: ['./result-list-as-list.component.scss']
})
export class ResultListAsListComponent extends ResultListComponent {

  faRocket = faRocket
  faEdit = faEdit
  constructor(route: Router, notification: NotificationService, userInfoService: AuthGuardService) {
    super(route, notification, userInfoService)
  }
  action :  { id: string, name: string }
  selected: string[] = []
  @Input() client: ProductService;
  @Input() moveActionTarget: { id: string, name: string }[]


  ngOnChanges(change: any): void {
      if ( change.filter) {
        this.selected = []
      }
      super.ngOnChanges(change)
  }
  select(id: string) {
    const findIndex = this.selected.findIndex((e) => e === id)
    if (findIndex !== -1) {
      this.selected.splice(findIndex,1)
    } else {
      this.selected.push(id)
    }
  }

  isSelected(id: string) {
    const findIndex = this.selected.findIndex((e) => e === id)
    return findIndex !== -1
  } 

  selectAll() {
    this.selected = this.array.map(e => e._id)
  }
  unselectAll() {
    this.selected = []
  }

  selectAction(action: string) {
    this.action = this.moveActionTarget.find((e) => e.name === action)
  }

  moveActionNames() {
    return this.moveActionTarget.map(e => e.name)
  }
  performAction() {
    this.client.setAssociatedNode(this.selected, this.action.id).subscribe(next => {
      this.notification.succes('SUSSES', '')
      this.array = this.array.filter((e) => !this.selected.find((s) => s === e._id))
      this.selected = []
      },
      error => {
        this.notification.error('ERROR filters', error)
      })
  }


}
