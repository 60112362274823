<div class="header">
  <h3>Information de Facturation</h3>
  <app-minus-button id="cancel" class="close" [mat-dialog-close]></app-minus-button>
</div>
<mat-dialog-content>
  <div class="form" [formGroup]="objectAsForm">
    <div class="identity">
      <div class="input-wrapper">
        <h5>Prénom</h5>
        <input id="firstname" class="effect" type="text" autocomplete="given-name" formControlName="firstName" required 
        placeholder="John">
        <span class="focus-border"></span>
        <p *ngIf="objectAsForm?.controls?.firstName?.errors?.['required']">*Obligatoire</p>
        
      </div>
      <div class="input-wrapper">
        <h5>Nom</h5>
      <input id="lastname" class="effect" type="text" autocomplete="family-name" formControlName="lastName" required placeholder="Doe">
      <span class="focus-border"></span>
      <p *ngIf="objectAsForm?.controls?.lastName?.errors?.['required']">*Obligatoire</p>
    </div>
  </div>
  <div class="address">
    <div class="address" formGroupName="address">
    <div class="input-wrapper">
      <h5>Numéro et rue</h5>
      <input id="street" class="effect" type="text" autocomplete="street-address" formControlName="street" placeholder="Rue de la paix">
      <span class="focus-border"></span>
      <p *ngIf="objectAsForm?.controls?.address?.controls?.street?.errors?.['required']">*Obligatoire</p>
    </div>
    <div class="town">
      <div class="input-wrapper">
        <h5>Code postale</h5>
        <input  id="zipcode" class="effect" type="text" autocomplete="postal-code" formControlName="zipcode" required placeholder="75005">
        <span class="focus-border"></span>
        <p *ngIf="objectAsForm?.controls?.address?.controls?.zipcode?.errors?.['required']">*Obligatoire</p>
      </div>
      <div class="input-wrapper">
        <h5>Ville</h5>
        <input  id="city" class="effect" type="text" autocomplete="address-level2" formControlName="city" required placeholder="Paris">
        <span class="focus-border"></span>
        <p *ngIf="objectAsForm?.controls?.address?.controls?.city?.errors?.['required']">*Obligatoire</p>
      </div>
    </div>
    <div class="input-wrapper">
      <h5>Pays</h5>
      <input  id="country" class="effect" type="text" autocomplete="country-name" formControlName="country" required placeholder="France">
      <span class="focus-border"></span>
      <p *ngIf="objectAsForm?.controls?.address?.controls?.country?.errors?.['required']">*Obligatoire</p>
    </div>
  </div>
</div>
<div class="contact">
  <div class="input-wrapper">
    <h5>Email</h5>
    <input  id="email" class="effect" type="text"  autocomplete="email" formControlName="email" appEmail required placeholder="john.doe@gmail.com">
    <span class="focus-border"></span>
    <p *ngIf="objectAsForm?.controls?.email?.errors?.['required']">*Obligatoire</p>
    <p *ngIf="objectAsForm?.controls?.email?.errors?.['forbiddenEmail']">*Email invalide</p>
  </div> 
  <div class="input-wrapper">
    <h5>Téléphone</h5>
    <input  id="cellular-number" class="effect" type="text" autocomplete="tel" formControlName="cellularNumber" required placeholder="+33 (4)8 15 16 23 42">
    <span class="focus-border"></span>
    <p *ngIf="objectAsForm?.controls?.cellularNumber?.errors?.['required']">*Obligatoire</p>
  </div>
</div>
</div>
</mat-dialog-content>
<mat-dialog-actions>
  <app-cta-button id="save" mat-button [text]="'Sauvegarder'" (click)="validateForm()" cdkFocusInitial>Install</app-cta-button>
</mat-dialog-actions>
