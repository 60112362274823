import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-visible-button',
  templateUrl: './visible-button.component.html',
  styleUrls: [
    './visible-button.component.scss', 
    '../button.component.scss'
  ]
})
export class VisibleButtonComponent {

    faEye = faEye
    faEyeSlash = faEyeSlash

    @Input() isVisible: boolean;
    @Input() _id: string;
    @Output() toggle = new EventEmitter()

    toggleEvent() {
      this.isVisible = !this.isVisible
      this.toggle.emit({ _id: this._id, isVisible: this.isVisible })
    }
  }
