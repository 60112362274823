import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Tree, TreeNode } from 'gul-si-commons';
import { UtilService } from 'src/app/service/utils.service';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent {
  faChevronRight = faChevronRight
  faChevronDown = faChevronDown
  faChevronUp = faChevronUp



  @Input() tree: Tree
  @Output() addSubNodeEvent = new EventEmitter<TreeNode>()
  @Output() deleteNodeEvent = new EventEmitter<TreeNode>()
  @Output() selectNodeEvent = new EventEmitter<TreeNode>()
  @Output() moveNode = new EventEmitter<TreeNode>()
  openAndDisplayedNode = []


  constructor(private utilService: UtilService) {

  }

  toggleOpenNode(node: TreeNode) {
    const id = node._id
    const found = this.openAndDisplayedNode.find((openNodeId) => {return openNodeId === id})
    if(!found)
      this.openAndDisplayedNode.push(id)
    else
      this.openAndDisplayedNode.splice(this.openAndDisplayedNode.indexOf(id), 1)
    this.selectNodeEvent.emit(node)
  }

  isOpen(id:string) {
    return !this.openAndDisplayedNode.find((openNodeId) => {return openNodeId === id})
  }

  deleteNode(node: TreeNode) {
    this.deleteNodeEvent.emit(node)
  }

  addSubNode(node: TreeNode) {
    this.addSubNodeEvent.emit(node)
  }

  moveUpNode(node: TreeNode, parent: TreeNode) {
      let index = parent.childs.indexOf(node)
      this.utilService.ArrayMoveUp(parent.childs, index)
      this.moveNode.emit(parent)
  }

  moveDownNode(node: TreeNode, parent: TreeNode) {
    let index = parent.childs.indexOf(node)
    this.utilService.ArrayMoveDown(parent.childs, index)
    this.moveNode.emit(parent)
}


}
