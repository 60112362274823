import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: [
    './switch-button.component.scss',
    '../button.component.scss'
  ]
})
export class SwitchButtonComponent {
  @Input() rightLabel: string = 'ON';
  @Input() leftLabel: string = 'OFF';
  @Input() isOn = false;
  @Input() disabled = false;
  @Output() switchChange = new EventEmitter<string>();

  switch(value: boolean): void {
    if(this.disabled)
      return
    this.isOn = value;
    this.switchChange.emit(this.isOn ? this.rightLabel : this.leftLabel);
  }
}
