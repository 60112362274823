import {NgModule} from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./page/home/home.component";
import {ProductPageComponent} from "./page/navigation/product/product-page.component";
import {CatalogueComponent} from "./page/catalogue/catalogue.component";
import {ProductEditComponent} from "./page/catalogue/product-edit/product-edit.component";
import {AuthGuardService} from "./core/services/auth/auth-guard.service";
import {CartPageComponent} from "./page/cart/cart.component";
import { ProductInventoryComponent } from './page/catalogue/product-inventory/product-inventory.component';
import { UserDashboardComponent } from './page/user-dashboard/user-dashboard.component';
import { OrderDashboardComponent } from './page/oms/order-dashboard/order-dashboard.component';
import { UniversComponent } from './page/navigation/univers/univers.component';
import { SearchComponent } from './page/navigation/search/search.component';
import { PromotionComponent } from './page/catalogue/promotion/promotion.component';
import { OrderPageComponent } from './page/oms/order/order.component';


const routes: Routes = [
  {path: 'catalogue', component: CatalogueComponent, canActivate : [AuthGuardService], data: {roles : ['office']}},
  {path: 'catalogue/arborescence', component: ProductInventoryComponent, canActivate : [AuthGuardService], data: {roles : ['office']}},
  {path: 'catalogue/promotions', component: PromotionComponent, canActivate : [AuthGuardService], data: {roles : ['office']}},
  {path: 'panier', component: CartPageComponent, canActivate : [AuthGuardService]},
  {path: 'produit/:productId', component: ProductPageComponent},
  {path: 'commandes', component: UserDashboardComponent, canActivate : [AuthGuardService]},
  {path: 'commande/:orderId', component: OrderPageComponent, canActivate : [AuthGuardService]},
  {path: 'gestion-commandes', component: OrderDashboardComponent, canActivate : [AuthGuardService], data: {roles : ['order']}},
  {path: 'produit/:productId/edit', component: ProductEditComponent, canActivate : [AuthGuardService], data: {roles : ['office']}},
  {path: 'rechercher/:name', component: SearchComponent},
  {path: ':first', component: UniversComponent},
  {path: ':first/:second', component: UniversComponent},
  {path: ':first/:second/:third', component: UniversComponent},
  
  {path: '', component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
