import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CartSlot, Product } from 'gul-si-commons';

@Component({
  selector: 'app-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent  {
    @Input() articles: CartSlot[];
    @Input() disabled = false

    @Output() plusTriggerEvent = new EventEmitter<CartSlot>()
    @Output() minusTriggerEvent = new EventEmitter<CartSlot>()

    

    plusTrigger(item: CartSlot) {
      this.plusTriggerEvent.emit(item)
    }

    minusTrigger(item: CartSlot) {
      this.minusTriggerEvent.emit(item)
    }
 
}
