<div *ngFor="let node of tree?.root?.childs"> 
    <!-- First tree deep level -->
    <div  class="row">
        <app-minus-button (click)="deleteNode(node)"></app-minus-button>
        <fa-icon (click)="toggleOpenNode(node)" *ngIf="isOpen(node._id)" [icon]="faChevronRight"></fa-icon>
        <fa-icon (click)="toggleOpenNode(node)" *ngIf="!isOpen(node._id)" [icon]="faChevronDown"></fa-icon>
        <p (click)="toggleOpenNode(node)">{{node.label}}</p>
        <div class="up-down-button">
            <button><fa-icon [icon]="faChevronUp" (click)="moveUpNode(node, tree.root)"></fa-icon></button>
            <button><fa-icon [icon]="faChevronDown" (click)="moveDownNode(node, tree.root)"></fa-icon></button>
        </div>
    </div>
    <div [class.hidden]="isOpen(node._id)" *ngFor="let subNode of node?.childs"> 
        <!-- Second tree deep level -->
        <div   class="row">
            <div  (click)="toggleOpenNode(subNode)" class="spacer"></div>
            <app-minus-button (click)="deleteNode(subNode)"></app-minus-button>
            <fa-icon  (click)="toggleOpenNode(subNode)" *ngIf="isOpen(subNode._id)" [icon]="faChevronRight"></fa-icon>
            <fa-icon   (click)="toggleOpenNode(subNode)" *ngIf="!isOpen(subNode._id)" [icon]="faChevronDown"></fa-icon>
            <p  (click)="toggleOpenNode(subNode)">{{subNode.label}}</p>
            <div class="up-down-button">
                <button><fa-icon [icon]="faChevronUp" (click)="moveUpNode(subNode, node)"></fa-icon></button>
                <button><fa-icon [icon]="faChevronDown" (click)="moveDownNode(subNode, node)"></fa-icon></button>
            </div>
        </div>
        <div [class.hidden]="isOpen(subNode._id)" *ngFor="let subsubNode of subNode?.childs"> 
            <!-- Thrid tree deep level -->
            <div  class="row" >
                <div  (click)="toggleOpenNode(subsubNode)" class="spacer"></div>
                <div  (click)="toggleOpenNode(subsubNode)" class="spacer"></div>
                <app-minus-button (click)="deleteNode(subsubNode)"></app-minus-button>
                <p  (click)="toggleOpenNode(subsubNode)">{{subsubNode.label}}</p>
                <div class="up-down-button">
                    <button><fa-icon [icon]="faChevronUp" (click)="moveUpNode(subsubNode, subNode)"></fa-icon></button>
                    <button><fa-icon [icon]="faChevronDown" (click)="moveDownNode(subsubNode, subNode)"></fa-icon></button>
                </div>
            </div>
        </div>
        <div class="add-row"><div class="spacer"></div><div class="spacer"></div><app-plus-button (click)="addSubNode(subNode)" [class.hidden]="isOpen(subNode._id)"></app-plus-button> </div>         
    </div>
    <div class="add-row"><div class="spacer"></div><app-plus-button (click)="addSubNode(node)" [class.hidden]="isOpen(node._id)"></app-plus-button>     </div>         
</div>   
<div id="add-node" class="add-row"><app-plus-button (click)="addSubNode(tree.root)"></app-plus-button></div>

