
<div class="screen-margin">
  <section>
    <h1>{{name}}</h1>
    <app-breadcrumb [path]="path"></app-breadcrumb>
      <div class="wrapper">
        <div *ngFor="let univers of node?.childs" class="item" [routerLink]="['./', univers?.code]">
          <h5>{{univers?.label}}</h5>
      </div>
    </div>
    <app-result-list [client]="catalogue" [filter]="filter"></app-result-list>
  </section>
</div>
