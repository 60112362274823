    <section class="screen-margin">
        <!-- Customer information -->
        <div class="shipping-billing">
            <div  class="billing">
                <h3><strong>Facturation</strong></h3>
                <app-customer-picker [customer]="cartService?.cart?.customer" [disabled]="false" (emitSave)="cartService.setCustomer(cartService?.cart?.customer)"></app-customer-picker>
            </div>
            <div  class="shipping">
                <h3><strong>Livraison</strong></h3>
                <app-customer-picker [customer]="cartService?.cart?.shipping"></app-customer-picker>
            </div>
        </div>
    </section>
    <section class="cart-overview screen-margin">
        <!-- Section containing two colum for product list and for cart summary     -->
        <app-article-list [articles]="cartService?.cart?.articles" (plusTriggerEvent)="cartService.add($event)" (minusTriggerEvent)="cartService.remove($event)"></app-article-list>
        <div class="summary"> 
            <!-- Delivery and return date-dime -->
            <div class="shipping-date">
                <div class="row">
                    <h3><fa-icon [icon]="faTruckFast"></fa-icon>Date de livraison</h3>
                </div>
                <div class="row">
                    <app-date-picker  [locale]="'fr'" [date]="cartService?.cart?.startDate?.date"  [slot]="cartService?.cart?.startDate?.slot" (emitSelectedDate)="cartService?.setStartDate( $event)"></app-date-picker> 
                </div>
            </div>
            <div class="shipping-date">
                <div class="row">
                    <h3><fa-icon [icon]="faTruckFast"></fa-icon>Date de retour</h3>
                </div>
                <div class="row">
                    <app-date-picker [locale]="'fr'" [date]="cartService?.cart?.endDate?.date" [slot]="cartService?.cart?.endDate?.slot" (emitSelectedDate)="cartService?.setEndDate( $event)"></app-date-picker>
                </div>
            </div>
            <!-- Cart sumary detail -->
            <div class="resume">
                <div class="row">
                    <h4>Sous total HT/jours</h4>
                    <p>{{cartService?.cart?.dailyPrice()?.getHT() | number: '1.2-2'}}</p>
                </div>
                <div class="row">
                    <h4>Retrait au dépôt</h4>
                    <p>Gratuit</p>
                </div>
            </div>
                        <!-- Cart sumary detail -->
            <div class="resume">
                <div class="row">
                    <div class="row" *ngIf="cartService?.cart?.promotions?.discountCode">
                        <h4>{{cartService?.cart?.promotions?.discountCode?.code}} : {{ cartService?.cart?.promotions?.discountCode?.percent}} %</h4>
                        <app-icon-button [icon]="faTrash" (click)="cartService.deletePromotion(cartService?.cart?.promotions?.discountCode)"></app-icon-button>
                    </div>
                    <div class="row" *ngIf="!cartService?.cart?.promotions?.discountCode">
                        <app-default-input [text]="'Code de Réduction'" [placeholder]="'MONCODE'" [input]="codePromotionControl"></app-default-input>
                        <app-icon-button [icon]="faCheck"  (click)="addPromotion(codePromotionControl.value)"></app-icon-button>
                    </div>
            
                    <p>-{{cartService?.cart?.promotions?.discountCode?.apply(cartService?.cart?.totalPrice())?.getHT() | number: '1.2-2'}}</p>
                </div>
            </div>
            <!-- Total price and validate button -->
            <div class="total">
                <div class="row">
                    <h4>TVA</h4>
                    <h5>{{cartService?.cart?.totalPrice()?.getTVA()| number: '1.2-2'}}</h5>
                </div>
                <div class="row">
                    <h3>Total TTC</h3>
                    <h4>{{cartService?.cart?.totalPrice()?.getTTC() | number: '1.2-2'}}</h4>
                </div>
                <div class="row">
                    <app-cta-button class="validate" (click)="cartService.createOrder()" [text]="'Valider'"></app-cta-button>
                </div>
            </div>
       </div>
    </section>
